import './App.css';
import BackgroundImg from './srcAssets/background.jpg';
import ProfileImg from './srcAssets/profileimage.jpg';

function App() {
  return (
    <div className="App">

      <header className="App-header">
        <section id="home">
          <div class="row">
          </div>
          {/* <h1>Welcome to Scrolling Nav</h1> */}
          {/* <p class="lead">A landing page template freshly redesigned for Bootstrap 4</p> */}
        </section>
      </header>

      <body className="App-body" style={{  
            backgroundImage: `url(${BackgroundImg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
      }}>
       
        <div class="container">
          <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top mb-5 shadow" id="mainNav">
            <div class="container">
              <a class="navbar-brand js-scroll-trigger" href="#page-top"><i class="far fa-file"></i> &nbsp; Online CV</a>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item active">
                    <a class="nav-link js-scroll-trigger" href="#home">Home
                      <span class="sr-only">(current)</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link js-scroll-trigger" href="#aboutme">About Me</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link js-scroll-trigger" href="#experience">Experience</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link js-scroll-trigger" href="#skills">Skills</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link js-scroll-trigger" href="#projects">Projects</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link js-scroll-trigger" href="#education">Education</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link js-scroll-trigger" href="#interests">Interests</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>

        <div class="container">
          <div class="card border-0 shadow my-5">
            <div class="card-body p-5">
            
              <section id="aboutme">
                <div class="container">
                  <div class="row">
                    {/* left side aboutme info */}
                    <div class="col-lg-8 mx-auto">
                      <h1 class="font-weight-light">Daniel Parcesepe</h1>
                      <h2 class="font-weight-light">IT </h2>
                        <p class="lead"> &nbsp;</p>
                        <p class="lead"><i class="fas fa-mobile-alt"></i> +61 (0) 415 454 404</p>
                        <p class="lead"><i class="far fa-envelope"></i>  <a class="pagelinks" href="mailto:daniel.parcesepe@outlook.com">daniel.parcesepe@outlook.com</a></p>
                        <p class="lead"><i class="far fa-file"></i>      <a class="pagelinks" href="/assets/daniel.parcesepe.resume.pdf">Download Resume</a></p>
                      <h2 class="font-weight-light">About Me</h2>
                        <p class="lead">career blerb</p>
                    </div>

                    {/* right side profile image */}
                    <div class="col-lg-4 mx-auto">
                      <img src={ProfileImg} alt="profileimage" class="rounded mx-auto d-block" />
                    </div>
                  </div>
                </div>
              </section>

              <section id="experience">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12 mx-auto">
                      <h2 class="font-weight-light">Experience</h2>
                        <p class="lead">this will be a list</p>              
                        <ul>
                          <li>details 1</li>
                          <li>details 2</li>
                          <li>details 3</li>
                          <li>details 4</li>
                        </ul>
                    </div>
                  </div>
                </div>
              </section>

              <section id="skills">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12 mx-auto">
                      <h2 class="font-weight-light">Skills</h2>
                      <p class="lead">this will be a list</p>              
                      <ul>
                        <li>details 1</li>
                        <li>details 2</li>
                        <li>details 3</li>
                        <li>details 4</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>

              <section id="projects">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12 mx-auto">
                      <h2 class="font-weight-light">Projects</h2>
                      <p class="lead">this will be a list</p>
                      <ul>
                        <li>details 1</li>
                        <li>details 2</li>
                        <li>details 3</li>
                        <li>details 4</li>
                      </ul>              
                    </div>
                  </div>
                </div>
              </section>


              <section id="education">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12 mx-auto">
                      <h2 class="font-weight-light">Education</h2>
                      <p class="lead">this will be a list</p>
                      <ul>
                        <li>details 1</li>
                        <li>details 2</li>
                        <li>details 3</li>
                        <li>details 4</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>

              <section id="interests">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12 mx-auto">
                      <h2 class="font-weight-light">Interests</h2>
                      <p class="lead">this will be a list</p>
                      <ul>
                        <li>details 1</li>
                        <li>details 2</li>
                        <li>details 3</li>
                        <li>details 4</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>

              <section id="endofpage">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-1 mx-auto">
                      <a class="pagelinks js-scroll-trigger" href="#page-top"><i class="fas fa-chevron-circle-up fa-2x"></i></a>
                    </div>
                  </div>
                </div>
              </section>


            </div>
          </div>
        </div>
      </body>

      <footer className="App-footer" >
        <div class="py-5 bg-dark">
          <div class="container">
            <p class="m-0 text-center text-white">Copyright &copy; Your Website 2020</p>
          </div>
        </div>          
      </footer>


    </div>
  );
}

export default App;
